.mdl-button--raised.mdl-button--colored {
  background: #be0c1d;
}

.mdl-button--raised.mdl-button--colored:hover {
  background: rgb(133, 8, 20);
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background: #be0c1d;
}
